















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class CampaignItem extends Vue {
    @Prop() campaign!: any
}
