





















































































import CampaignItem from '@/components/common/CampaignItem.vue'
// import DeleteCampaignPopup from '@/components/common/DeleteCampaignPopup.vue'
// import CampaignPopup from '@/components/common/CampaignPopup.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

let startDate:Date = new Date()
let endDate:Date = new Date()
startDate.setDate(startDate.getDate() - 7)

@Component({
  components: {
    CampaignItem,
    DateRangePicker
  }
})
export default class Campaigns extends Vue {
public productId: any = ''
public inStoreQuery = ''
public inStoreLimit = 100
public inStoreOffset = 0

public dateRange: any = {
  startDate: startDate,
  endDate: endDate
}
public atHomeQuery = ''
public atHomeLimit = 100
public atHomeOffset = 0

public inStoreCampaigns: any[] = []
public atHomeCampaigns: any[] = []

public inStoreTotalCount = 0
public atHomeTotalCount = 0
public brandId = 0
public busy: boolean = false

public campaignDetails: object = {}
public products: any = []
public productLimitPerPage: number = 100
public productOffset: number = 0

getInStoreCampaigns (clear = true) {
  this.busy = true
  this.$store.dispatch('getCampaigns', { query: this.inStoreQuery, limit: this.inStoreLimit, offset: this.inStoreOffset, brand_id: this.brandId, type: 'GEO', startDate: Date.parse(this.dateRange.startDate), endDate: Date.parse(this.dateRange.endDate), productId: this.productId }).then((response) => {
    if (response.options.query === this.inStoreQuery && response.options.limit === this.inStoreLimit && this.inStoreOffset === response.options.offset) {
      if (clear) this.inStoreCampaigns = []
      this.inStoreCampaigns.push(...response.response.body.data)
      this.inStoreTotalCount = response.response.body.count
      this.busy = false
    }
  }, (response) => {
    this.busy = false
  })
}
getAllCampaigns () {
  this.getAtHomeCampaigns()
  this.getInStoreCampaigns()
  console.log('called')
}

getAtHomeCampaigns (clear = true) {
  this.busy = true
  this.$store.dispatch('getCampaigns', { query: this.atHomeQuery, limit: this.atHomeLimit, offset: this.atHomeOffset, brand_id: this.brandId, type: 'NON_GEO', startDate: Date.parse(this.dateRange.startDate), endDate: Date.parse(this.dateRange.endDate) }).then((response) => {
    if (response.options.query === this.atHomeQuery && response.options.limit === this.atHomeLimit && this.atHomeOffset === response.options.offset) {
      if (clear) this.atHomeCampaigns = []
      this.atHomeCampaigns.push(...response.response.body.data)
      this.atHomeTotalCount = response.response.body.count
      this.busy = false
    }
  }, (response) => {
    this.busy = false
  })
}
getProducts () {
  this.$store.dispatch('getBrandProducts', { brandId: this.brandId, limit: this.productLimitPerPage, offset: this.productOffset, sort: 'name-ASC' }).then((response: any) => {
    this.products.push(...response.response.body.data)
    if (this.products.length < response.response.body.count) {
      this.productOffset += this.productLimitPerPage
      this.getProducts()
    }
  })
}
dateFormat (classes:any, date:any) {
  if (!classes.disabled) {
    classes.disabled = date.getTime() > new Date()
  }
  return classes
}

loadMore () {
  this.$data.offset += this.$data.limit
  this.getInStoreCampaigns(false)
}

@Watch('inStoreQuery')
onInStoreSearch () {
  Vue.common.delay(() => {
    this.inStoreOffset = 0
    this.getInStoreCampaigns()
  }, 500)()
}

@Watch('atHomeQuery')
onAtHomeSearch () {
  Vue.common.delay(() => {
    this.atHomeOffset = 0
    this.getAtHomeCampaigns()
  }, 500)()
}

created () {
  this.brandId = this.$store.state.userData.brandId
  this.getInStoreCampaigns()
  this.getAtHomeCampaigns()
  this.getProducts()
}

refresh () {
  this.inStoreOffset = 0
  this.getInStoreCampaigns()
  this.getAtHomeCampaigns()
}

editCampaignDetails (campaign:object) {
  this.campaignDetails = campaign
}

get hasInStoreLoadMore (): boolean {
  return this.inStoreCampaigns.length < this.inStoreTotalCount
}
get hasAtHomeLoadMore (): boolean {
  return this.atHomeCampaigns.length < this.atHomeTotalCount
}
}
