var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c(
        "span",
        { staticClass: "title", attrs: { title: _vm.campaign.name } },
        [_vm._v(_vm._s(_vm.campaign.name))]
      ),
      _c("br"),
      _c("br"),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "a",
          {
            staticClass: "btn-positive",
            staticStyle: {
              display: "block",
              "text-align": "center",
              width: "50%",
              margin: "auto",
              "text-decoration": "none"
            },
            attrs: {
              href: "/brand/campaign-insights/" + _vm.campaign.id,
              "data-toggle": "modal",
              type: "button"
            }
          },
          [_vm._v("INSIGHTS")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }